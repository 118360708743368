import * as React from 'react';
import { exportComponent } from "../../exportComponent";

export default function setupExports() {

	exportComponent("space-ui-demo",
		async () => {
			const { UIDemo } =
			await import(/* webpackChunkName: 'react-space-uidemo' */ "./UIDemo");
			return <UIDemo />
		}
	);

	exportComponent("space-demo-anchored-left-right",
		async () => {
			const { SpaceDemoAnchoredLeftRight } = 
			await import(/* webpackChunkName: 'react-space-demos' */ "./SpaceDemos"); 
			return <SpaceDemoAnchoredLeftRight />; 
		}
	);

	exportComponent("space-demo-anchored-top-bottom",
		async () => {
			const { SpaceDemoAnchoredTopBottom } = 
			await import(/* webpackChunkName: 'react-space-demos' */ "./SpaceDemos"); 
			return <SpaceDemoAnchoredTopBottom />; 
		}
	);

	exportComponent("space-demo-resizable-left-right",
		async () => {
			const { SpaceDemoResizableLeftRight } = 
			await import(/* webpackChunkName: 'react-space-demos' */ "./SpaceDemos"); 
			return <SpaceDemoResizableLeftRight />; 
		}
	);

	exportComponent("space-demo-resizable-top-bottom",
		async () => {
			const { SpaceDemoResizableTopBottom } = 
			await import(/* webpackChunkName: 'react-space-demos' */ "./SpaceDemos"); 
			return <SpaceDemoResizableTopBottom />; 
		}
	);

	exportComponent("space-demo-resizable-constrained",
		async () => {
			const { SpaceDemoResizableConstrained } = 
			await import(/* webpackChunkName: 'react-space-demos' */ "./SpaceDemos"); 
			return <SpaceDemoResizableConstrained />; 
		}
	);

	exportComponent("space-demo-nested-1",
		async () => {
			const { SpaceDemoNested1 } = 
			await import(/* webpackChunkName: 'react-space-demos' */ "./SpaceDemos"); 
			return <SpaceDemoNested1 />; 
		}
	);

	exportComponent("space-demo-nested-2",
		async () => {
			const { SpaceDemoNested2 } = 
			await import(/* webpackChunkName: 'react-space-demos' */ "./SpaceDemos"); 
			return <SpaceDemoNested2 />; 
		}
	);

	exportComponent("space-demo-scrollable-1",
		async () => {
			const { SpaceDemoScrollable1 } = 
			await import(/* webpackChunkName: 'react-space-demos' */ "./SpaceDemos"); 
			return <SpaceDemoScrollable1 />; 
		}
	);

	exportComponent("space-demo-stacked-1",
		async () => {
			const { SpaceDemoStacked1 } = 
			await import(/* webpackChunkName: 'react-space-demos' */ "./SpaceDemos"); 
			return <SpaceDemoStacked1 />; 
		}
	);

	exportComponent("space-demo-layers-1",
		async () => {
			const { SpaceDemoLayers1 } = 
			await import(/* webpackChunkName: 'react-space-demos' */ "./SpaceDemos"); 
			return <SpaceDemoLayers1 />; 
		}
	);
}