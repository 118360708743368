import exportReactSpaceDemos from "./components/react-spaces-demos/Exports";
import "./App.scss";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import ReactDOM from "react-dom";
import React from "react";

// React Space demos
exportReactSpaceDemos();

serviceWorker.unregister();

window.addEventListener("load", () => {
  var pres = document.querySelectorAll("pre>code");
  for (var i = 0; i < pres.length; i++) {
    (window as any).hljs.highlightBlock(pres[i]);
  }
});

ReactDOM.render(<App />, document.getElementById("root"));
