import React from 'react';
import ReactDOM from 'react-dom';

interface IExportedComponent {
	component: string,
	create: (e: Element) => Promise<React.ReactNode>
}
const exportedComponents : IExportedComponent[] = [];
export const exportComponent = (name: string, action: (e: Element) => Promise<React.ReactNode>) =>
	exportedComponents.push({ component: name, create: action });

window.addEventListener("load", async () => {
	const reactContainers = document.getElementsByClassName("react-root");
	for (let i = 0; i < reactContainers.length; i++) {
		const componentName = reactContainers[i].getAttribute("data-component");
		if (componentName) {
			const setup = exportedComponents.find(c => c.component === componentName);
			if (setup) {
				const component = await setup.create(reactContainers[i]);
				if (component) {
					ReactDOM.render(component as React.DOMElement<React.DOMAttributes<Element>, Element>, reactContainers[i]);
				}
			}
		}
	}
});
