import React from "react";
import * as Space from "react-spaces";

const App: React.FC = () => {
  return (
    <div className="App">
      <Space.ViewPort>
        <Space.LeftResizable
          size="25%"
          style={{ backgroundColor: "Red" }}
          onResizeStart={() => console.log("Resize start")}
          onResizeEnd={(newSize) => console.log(`Resize end: ${newSize}`)}
        ></Space.LeftResizable>
      </Space.ViewPort>
    </div>
  );
};

export default App;
